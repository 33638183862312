import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';


// 开启router
Vue.use(VueRouter)
Vue.use(VueMeta, {
    // 定义组件中的数据对象
    keyName: 'head',
});


// mode 说明了我们使用的是history模式，如果不设置mode，则会使用默认的hash模式。
const router = new VueRouter({
    // 路由模式
    mode: 'history',
    // 配置路由对象
    routes: [
        {
            name: 'Home',
            path: '/',
            component: () => import('@/pages/CategoryPage'),
            // component: () => import('@/pages/HomePage'),
        },
        {
            name: 'Category',
            path: '/category',
            component: () => import('@/pages/CategoryPage'),
        },
        {
            name: 'Mobile',
            path: '/mobile',
            component: () => import('@/pages/MobilePage'),
        },
        {
            name: 'List',
            path: '/list/:searchString',
            component: () => import('@/pages/ListPage'),
            props: (route) => ({ searchString: route.params.searchString }),
        },
        {
            name: 'Play',
            path: '/play/movie/:uniqueId',
            component: () => import('@/pages/PlayPage'),
            props: (route) => ({ uniqueId: route.params.uniqueId }),
        },
        {
            name: 'Rinhome-Movie',
            path: '/rinhome/movie/',
            component: () => import('@/pages/RinhomePage'),
            props: () => ({ mediaType: "movie" }),
        },
        {
            name: 'Rinhome-Tv',
            path: '/rinhome/tv/',
            component: () => import('@/pages/RinhomePage'),
            props: () => ({ mediaType: "tv" }),
        },
        {
            name: 'Rinhome-Cartoon',
            path: '/rinhome/cartoon/',
            component: () => import('@/pages/RinhomePage'),
            props: () => ({ mediaType: "cartoon" }),
        },
        {
            name: '404',
            path: '/404',
            component: () => import('@/pages/NotFoundPage')
        },
        {
            path: '*', // 页面不存在的情况下会跳到404页面
            redirect: '/404',
            name: 'notFound',
            hidden: true
        }
    ]
})

export default router
