import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueCoreVideoPlayer from 'vue-core-video-player'
import Antd from 'ant-design-vue';
import VueLazyload from 'vue-lazyload'
import 'ant-design-vue/dist/antd.css';
import VueHorizontalList from "vue-horizontal-list";

import App from './App.vue'
import router from './router'
// 加载自定义字体
import '@/assets/font/font.css'


Vue.config.productionTip = false
// 加载 coreVideoPlayer
Vue.use(VueCoreVideoPlayer)
// 加载 ant design
Vue.use(Antd)
// 加载 vue cookies
Vue.use(VueCookies)
// 加载 horizontal list
Vue.use(VueHorizontalList)
// 加载 image lazy load
const loadingImage = require('@/assets/images/placeholder.png')
const errorImage = require('@/assets/images/placeholder.png')

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: errorImage,
    loading: loadingImage,
    attempt: 1
})

let instance = new Vue({
    router: router,
    render: h => h(App)
}).$mount('#app')

export default instance

